<template>
  <div @load="loading()">
    <div class="touch" @touchstart='touchstart' @touchmove='touchmove'>
      <topMb></topMb>
      <!-- 轮播 -->
      <el-carousel :height="banH">
        <el-carousel-item v-for="item in bannerMb" :key="item" class="carousel-item">
          <img ref="imgH" class="carousel-img" :src="item" @load="setbanH()" />
        </el-carousel-item>
      </el-carousel>
      <!-- 切换 -->
      <div class="main-travel ">
        <div class="train-box">
          <div class="app-container">
            <div class="col-md-12 travel-item-box">
              <el-image src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-1.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <div class="travel-item-md6" v-for="(item,i) in ticketArr" :key="i" @click="change(item)">
                <div :class="[active===item.id ? 'travel-item1' : 'travel-item']">
                  <el-image class="travel-item-img" :src="item.img" lazy></el-image>
                  <p class="travel-right">有效日
                    <span class="travel-right-day">
                      <span :style="{'color':item.fontcolor}">{{item.validityDay}}</span>&nbsp;天</span>
                  </p>
                  <p class="travel-detail" v-for="(item ,index) in splitBatName(item.describe)" :key="index">{{item}}</p>
                  <p class="travel-price" :style="{'color':item.fontcolor}">￥{{item.price}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 套餐内容 -->
      <div class="main-package">
        <div class="app-container">
          <div class="col-md-12  package-content-box">
            <div class="package-content flex-arround">
              <el-image class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="package-title">ALL PASS 套餐內容</p>
              <el-image class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
          </div>
          <div class="col-md-12 flex-justify-c">
            <div class="package-riding-content2">
              <div class="riding-box">
                <div class="riding-right">
                  <p class="riding-right-title">JR乘車券</p>
                  <p class="right-position">能夠搭乘指定座位</p>
                  <p class="riding-right-title2">關西特快列車HARUKA單程券坐票</p>
                  <p class="riding-right-title22">(關西機場站-京都站)</p>
                  <div class="riding-line"></div>
                  <p class="riding-right-title3">您也可以將關西特快列車HARUKA<br>列車單程票券更改為JR西日本鐵路周遊券</p>
                  <el-image class="riding-right-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/riding-2.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <el-image class="riding-copyright" :src="copyright" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" style="width: 309px;height:10px;margin-left: -12px;" alt="" />
                    </div>
                  </el-image>
                  <div class="col-md-12 five_days_box">
                    <div class="five_days_title" :class="[active===2 ? 'riding-bottom-1-1' : '']">
                      <p>{{planName_small}}</p>
                      <p>￥{{planPrice}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box" v-if="planName2 && planPrice2" :class="[active===2 ? 'riding-bottom-2-1' : '']">
                    <div class="five_days_title">
                      <p>{{planName2}}</p>
                      <p>￥{{planPrice2}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box" v-if="planName3 && planPrice3">
                    <div class="five_days_title">
                      <p>{{planName3}}</p>
                      <p>￥{{planPrice3}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box">
                    <div class="five_days_attention">
                      <p>※僅一次有效</p>
                      <p>※也能夠於天王寺•新大阪站下車</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- 日本环球影城 -->
          <div class="package-universal-content2 ">
            <div class="universal-left">
              <p class="universal-left-1">日本環球影城<br>1日周遊券</p>
              <el-image class="lazy universal-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/universal-zh1.jpg" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="universal-left-3">
                <span>除了榮獲主題樂園業界中最高榮譽<br>「Thea獎」的全球首創*瑪利歐園區及<br>「瑪利歐賽車～庫巴的挑戰書～™」，</span>
                <span>還有哈利波特的魔法世界™、小小兵等<br>包羅萬象的大規模遊樂設施，<br>讓大人小孩都能樂在其中！</span>
                <span>在充滿感動與興奮的園區遊玩，<br>讓您歡樂無限！</span>
              </p>
              <div class="universal-left-4">
                <p>*（合同會社）日本環球影城以任天堂品牌角色及遊戲世界為主題的遊樂設施的調查結果</p>
              </div>
            </div>
          </div>
          <!-- 观光设施共通劵 -->
          <div class="facilities-content2 ">
            <div class="facilities-box flex-arround">
              <el-image class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="facilities-title">觀光設施共通券<br><span>{{md3[0].num}}項設施</span></p>
              <el-image class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
            <div class="facilities-attention2 ">
              <p>1.各設施有可能臨時休業的形況，當日出發遊玩前請務必確認官網最新消息及營業時間。</p>
              <p>2.欲前往下個遊玩景點，需至少間隔2小時，才能掃碼入場。</p>
              <p>3.同一個設施不可重復入場。</p>
            </div>
            <div class="area-box">
              <div class="area-Box">
                <div class="col-md-12">
                  <div class="all-area" :style="{backgroundColor:(activeArr.includes(1)?'#ff7b00':'#fff'),color:(activeArr.includes(1)?'#fff':'#ff7b00')}" @click="activeArea({id:1,areaType:0})">所有地区
                  </div>
                </div>
                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                  <div class="area" :style="{backgroundColor:(activeArr.includes(2)?'#ff7b00':'#fff'),color:(activeArr.includes(2)?'#fff':'#ff7b00')}" @click="activeArea({id:2,areaType:2})">大阪</div>
                  <div class="area" :style="{backgroundColor:(activeArr.includes(3)?'#ff7b00':'#fff'),color:(activeArr.includes(3)?'#fff':'#ff7b00')}" @click="activeArea({id:3,areaType:1})">京都</div>
                </div>
                <div class="col-md-12">
                  <div class="area2" :style="{backgroundColor:(activeArr.includes(5)?'#ff7b00':'#fff'),color:(activeArr.includes(5)?'#fff':'#ff7b00')}" @click="activeArea({id:5,areaType:3})">滋賀·兵庫·和歌山</div>
                  <div class="col-md-12" style="display: flex;justify-content: space-between;">
                    <div class="area-md3" v-show="this.active===1" :style="{backgroundColor:(activeArr.includes(4)?'#ff7b00':'#fff'),color:(activeArr.includes(4)?'#fff':'#ff7b00')}" @click="activeArea({id:4,areaType:5})">石川</div>
                    <div class="area3" v-show="this.active===1" :style="{backgroundColor:(activeArr.includes(6)?'#ff7b00':'#fff'),color:(activeArr.includes(6)?'#fff':'#ff7b00')}" @click="activeArea({id:6,areaType:4})">山陰山陽</div>
                  </div>
                </div>
                <div class="col-md-12 search-bg" style="display: flex; justify-content: center;">
                  <div class="area-search" @click="drawer = true">搜尋</div>
                </div>
              </div>
              <div class="area-small-attention">
                <p>注意事項</p>
                <p>*ALL PASS的有效期為標題所示，與區域周遊券的有效期無關。</p>
                <p>*購買了關西特快列車HARUKA列車的單程票券以及區域周遊券的顧客請務必在ALL PASS的有效期間的<br>最終有效日之前進行兌換。</p>
                <p>*如果在有效期間的最終有效日未能進行兌換的話則無法使用。</p>
              </div>
              <el-image class="area-small-bg" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 抽屉 -->
    <div class="touch" @touchstart='touchstart' @touchmove='touchmove1' style="touch-action: pan-y!important;">
      <el-drawer title="" :visible.sync="drawer" :with-header="true" :destroy-on-close="true">
        <!-- 观光设施 抽屉-->
        <div class="main-facilities">
          <div class="facilities-box2">
            <el-image class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
              <div slot="placeholder" class="image-slot">
                <img :src="loadImg" class="loadImg" alt="" />
              </div>
            </el-image>
            <p class="facilities-title">觀光設施</p>
            <el-image class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
              <div slot="placeholder" class="image-slot">
                <img :src="loadImg" class="loadImg" alt="" />
              </div>
            </el-image>
          </div>
          <div class="col-md-12">
            <div class="area-title">
              <div class="area-title1">
                <div class="area-title-item">
                  <span :style="{backgroundColor:((activeArr.includes(2) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(2) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(2) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:2,areaType:2})">大阪</span>
                </div>
                <div class="area-title-item" :style="{borderRight:(this.active===1?'1px solid #ff7b00':'0')}">
                  <span :style="{backgroundColor:((activeArr.includes(3) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(3) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(3) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:3,areaType:1})">京都</span>
                </div>
                <div class="area-title-item2" v-show="this.active===1">
                  <span :style="{backgroundColor:((activeArr.includes(6) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(6) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(6) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:6,areaType:4})">山陰山陽</span>
                </div>
              </div>
              <div class="area-title1">
                <div class="area-title-item" v-show="this.active===1">
                  <span :style="{backgroundColor:((activeArr.includes(4) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(4) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(4) || activeArr.includes(1))?'10px':'0'),width:(activeArr.includes(4)?'52px':'52px')}" @click="activeArea({id:4,areaType:5})">石川</span>
                </div>
                <div class="area-title-item1">
                  <span :style="{backgroundColor:((activeArr.includes(5) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(5) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(5) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:5,areaType:3})">滋賀·兵庫·和歌山</span>
                </div>
              </div>
            </div>
          </div>
          <div class="facilities-item-box">
            <div class="col-md-12" v-for="(item,i) in showList" :key="i" @click="openSmall(item)">
              <div class="facilities-item" v-if="active=== 1">
                <el-image class="facilities-item-img" :src="item.img" lazy>
                  <div slot="placeholder" class="image-slot">
                    <img :src="loadImg" class="loadImg" alt="" />
                  </div>
                </el-image>
                <div class="facilities-item-title">
                  <p style="width: 293px;margin-bottom: 0;;" v-for="(item ,index) in splitBatName(item.ticketName3)" :key="index">
                    {{item}}</p>
                </div>
              </div>
              <div class="facilities-item" v-else v-show="active === 2 && item.activeId === 1">
                <el-image class="facilities-item-img" :src="item.img" lazy>
                  <div slot="placeholder" class="image-slot">
                    <img :src="loadImg" class="loadImg" alt="" />
                  </div>
                </el-image>
                <div class="facilities-item-title">
                  <p style="width: 293px;margin-bottom: 0;;" v-for="(item ,index) in splitBatName(item.ticketName3)" :key="index">
                    {{item}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 底部底图 -->
          <div class="language-bg-box">
            <div class="demo-image__lazy">
              <el-image class="language-bg" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png" style="width: 100%;"></el-image>
            </div>
          </div>
        </div>
        <!-- 观光设施详情页 -->
        <el-drawer title="" :append-to-body="true" :visible.sync="innerDrawer">
          <div class="touch" @touchstart='touchstart' @touchmove='touchmove2'>
            <el-carousel height="210px" :interval="2000" ref="nop" arrow="never">
              <el-carousel-item v-for="(item,index) in ticketDetials.tickeImg" :key="index">
                <img :src="item" style="height:100%;width:100%;" lazy />
              </el-carousel-item>
              <!-- </div> -->
            </el-carousel>
            <div class="main-facility">
              <div class="app-container">
                <p class="travel-copyright" v-if="ticketDetials.copyright">{{ticketDetials.copyright}}</p>
                <div class="col-md-12">
                  <div class="facility-title">{{ticketDetials.areaNAme}}</div>
                </div>
                <div class="col-md-12 facility-title2-box">
                  <div class="facility-title2">
                    <p v-for="(item ,index) in splitBatName(ticketDetials.ticketName4)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/facility_area.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <p class="facility-travel-p">
                    <a :href="ticketDetials.Http" target=_ blank>{{ticketDetials.Http}}</a>
                    <a :href="ticketDetials.Http2" target=_ blank>{{ticketDetials.Http2}}</a>
                  </p>
                  <!-- <p class="facility-travel-p" style="margin-left: 0;color: #000;">{{ticketDetials.Http2}}</p> -->
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-facilities.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Faci)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-cart.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Doer)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-name.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.time)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12 facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-calendar.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Calendar)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12 facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-price.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <p class="facility-travel-p">￥{{ticketDetials.price}}</p>
                </div>
                <div class="col-md-12 facility-travel-box" v-if="ticketDetials.content2">
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.content2)" :key="index">{{item}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-drawer>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import topMb from "@/components/top-all/top-Mball2.vue";
export default {
  components: { topMb },
  data() {
    return {
      banH: "",
      active: 1,
      animateTime: 3000, // 要和 css 的过渡一致
      ticketDescribe: "觀光設施共通券(29項設施)",
      planName: "關西廣域周遊券5天套票",
      planName_small: "關西廣域周遊券5天套票",
      planPrice: "27,700",
      planName2: "關西廣島周遊券5天套票",
      planPrice2: "32,000",
      planName3: "JR西日本全地區鐵路周遊券",
      planPrice3: "39,000",
      ticketDetials: {},
      showDialog: false,
      innerDrawer: false,
      drawer: false,
      startX: "",
      startY: "",
      moveY: "",
      moveX: "",
      startTime: "",
      activeArr: [1],
      activeAreaArr: [],
      showList: [],
      loadImg: require("@/assets/images/hfkansai/loading.png"),
      copyright: require("@/assets/images/allpass/copyright.png"),
      bannerMb: [
        "https://qbcdn.jtrchina.com/tripelletexploreallpass/banner/mb_banner.png",
        require("@/assets/images/allpass/MB_ch.png"),
      ],
      ticketArr: [
        {
          id: 1,
          type: 1,
          fontcolor: "#F98304",
          img: require("@/assets/images/allpass/travel-t.png"),
          ticketName: "西日本探遊券",
          validityDay: 4,
          price: "21,000~",
          describe: [
            "JR乘車券+",
            "觀光設施共通券29設施+",
            "日本環球影城1日周遊券",
          ],
        },
        {
          id: 2,
          type: 2,
          fontcolor: "#F4B22D",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-21.png",
          ticketName: "關西探遊券",
          validityDay: 3,
          price: "16,000~",
          describe: [
            "JR乘車券+",
            "觀光設施共通券16設施+",
            "日本環球影城1日周遊券",
          ],
        },
      ],
      scenicSpotArr: [
        // 京都
        {
          id: 1,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
          ticketName: ["京都鐵道博物館入場券"],
          ticketName2: ["京都鐵道博物館入場券"],
          ticketName3: ["京都鐵道博物館入場券"],
          ticketName4: ["京都鐵道博物館入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_D.jpg",
          ],
          Doer: ["JR 梅小路京都西站徒步約2分鐘"],
          time: ["10:00 ～ 17:00（最後入館16:30）"],
          Http: "http://www.kyotorailwaymuseum.jp/sc/ ",
          Faci: ["京都市下京區觀喜寺町", "（京都鐵道博物館售票處）"],
          Calendar: ["星期三，年底年初（可能臨時休館）"],
          areaType: 1,
          price: "1,200",
        },
        {
          id: 2,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
          ticketName: ["東映太秦映畫村入場券"],
          ticketName2: ["東映太秦映畫村入場券"],
          ticketName3: ["東映太秦映畫村入場券"],
          ticketName4: ["東映太秦映畫村入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_D.jpg",
          ],
          Doer: ["JR 花園站徒歩約13分鐘"],
          time: [
            "10:00 ～ 17:00",
            "※可能有臨時休館的情況發生， ",
            "營業時間請參照官方網站。",
          ],
          Http: "http://www.toei-eigamura.com/global/cn/ ",
          Faci: ["京都市右京區太秦東峰岡町10", "（東映太秦映畫村售票處）"],
          Calendar: ["請參照官方網站"],
          areaType: 1,
          price: "2,400",
        },
        {
          id: 3,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
          ticketName: ["嵐山美食優惠券", "嵐電1日通票"],
          ticketName2: ["嵐山美食優惠券", "嵐電1日通票"],
          ticketName3: ["嵐山美食優惠券", "嵐電1日通票"],
          ticketName4: ["嵐山美食優惠券", "嵐電1日通票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_D.jpg",
          ],
          Doer: ["JR 嵯峨嵐山站徒步約8分鐘"],
          time: ["嵐山電車「嵐山站Information」", "9:00 ～ 18:00"],
          Http: "https://www.tripellet.com/gurumeguri_map2022.pdf",
          Http2: "https://www.tripellet.com/gurumeguri2022.pdf",
          Faci: [
            "京都市右京區嵯峨",
            "天龍寺造路町20-2",
            "（嵐電嵐山站諮詢處）",
          ],
          Calendar: ["※各店家的營業時間，", "請參閱優惠劵上。"],
          areaType: 1,
          price: "1,000",
          isHttp: 1,
        },
        {
          id: 4,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
          ticketName: ["Sky Hop Bus京都", "雙層觀光巴士（當日劵）"],
          ticketName2: ["Sky Hop Bus京都", "雙層觀光巴士（當日劵）"],
          ticketName3: ["Sky Hop Bus京都", "雙層觀光巴士（當日劵）"],
          ticketName4: ["Sky Hop Bus京都", "雙層觀光巴士（當日劵）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_C.jpg",
          ],
          Doer: ["JR 京都站烏丸口徒步約1分鐘"],
          time: ["請參照官方網站（可能會臨時停開）"],
          Http: "https://skyhopbus.com/kyoto/ ",
          Faci: [
            "京都市下京區烏丸通塩小路下東塩小路町",
            "（京都觀定期觀光巴士乘車處）",
          ],
          Calendar: ["請參照官方網站"],
          areaType: 1,
          price: "2,500",
        },
        {
          id: 5,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
          ticketName: ["京都和服體驗夢館", "五條店和服和浴衣", "租借方案"],
          ticketName2: ["京都和服體驗夢館五條店和服和浴衣租借方案"],
          ticketName3: ["京都和服體驗夢館五條店和服和浴衣租借方案"],
          ticketName4: ["京都和服體驗夢館五條店和服和浴衣租借方案"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_D.jpg",
          ],
          Doer: ["JR 京都站搭地下鐵烏丸線至五條站", "徒步約3分鐘"],
          time: ["10:00 ～ 17:30（最後入館 16:00）"],
          Http: "https://cn-kyoto.yumeyakata.com/itiran-gojo/",
          Faci: ["京都市下京區塩竈町353"],
          Calendar: ["年底年初（12/31 ～ 1/3）"],
          areaType: 1,
          price: "3,850",
        },

        {
          id: 6,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
          ticketName: ["天橋立伊根周遊券2日券", "（巴士・船・電纜車）"],
          ticketName2: ["天橋立伊根周遊券2日券", "（巴士・船・電纜車）"],
          ticketName3: ["天橋立伊根周遊券2日券", "（巴士・船・電纜車）"],
          ticketName4: ["天橋立伊根周遊券2日券", "（巴士・船・電纜車）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_D.jpg",
          ],
          Doer: ["京都丹後鐵路天橋立站內"],
          time: ["天橋立Terminal Center", "內觀光服務處：10:00 ～ 16:30"],
          Http: "https://www.tankai.jp/tourist_tickets/",
          Faci: ["宮津市字文珠314-2", "（天橋立車站內「天橋立觀光協會中心」）"],
          Calendar: ["無"],
          areaType: 1,
          price: "2,600",
        },
        // 大阪
        {
          id: 7,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
          ticketName: ["梅田藍天大廈空中庭園", "眺望臺入場券"],
          ticketName2: ["梅田藍天大廈空中庭園", "眺望臺入場券"],
          ticketName3: ["梅田藍天大廈空中庭園", "眺望臺入場券"],
          ticketName4: ["梅田藍天大廈空中庭園", "眺望臺入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_D.jpg",
          ],
          Doer: ["JR 大阪站徒步約7分鐘"],
          time: ["9:30 ～ 22:30（最後入場22:00）"],
          Http: "https://www.skybldg.co.jp/cn/",
          Faci: ["大阪市北區大澱中1-1-88梅田藍天大廈", "（39樓售票櫃台）"],
          Calendar: ["無"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 8,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
          ticketName: ["日本最高樓—阿倍野「HARUKAS 300」展望臺"],
          ticketName2: ["日本最高樓—阿倍野「HARUKAS 300」展望臺"],
          ticketName3: ["日本最高樓—阿倍野「HARUKAS 300」展望臺"],
          ticketName4: ["日本最高樓—阿倍野「HARUKAS 300」展望臺"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_D.jpg",
          ],
          Doer: ["JR 天王寺站旁"],
          time: ["9:00 ～ 22:00（最後入場 19:30）"],
          Http: "https://www.abenoharukas-300.jp/zh/",
          Faci: ["大阪市阿倍野區阿倍野筋1-1-43", "（16層服務台）"],
          Calendar: ["無"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 9,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
          ticketName: ["大阪格蘭比亞大酒店", "甜點交響曲"],
          ticketName2: ["大阪格蘭比亞大酒店甜點交響曲"],
          ticketName3: ["大阪格蘭比亞大酒店", "甜點交響曲"],
          ticketName4: ["大阪格蘭比亞大酒店", "甜點交響曲"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_E1.jpg",
          ],
          Doer: ["連接著JR 大阪站 中央口"],
          time: [
            "甜點提供時間：11:00 ~ 16:00",
            "平日：10:00 ~ 20:00（Lo19:30）",
            "星期六：9:30 ~ 20:00（Lo19:30）",
            "星期日 節日：9:30 ~18:00（Lo17:30）",
          ],
          Http: "https://www.hotelgranviaosaka.jp/zh/dining/lobby-lounge/",
          Faci: ["大阪市北區梅田3丁目1-1（1樓櫃檯）"],
          Calendar: ["無"],
          areaType: 2,
          price: "2,300",
          content: "",
          content2: ["※圖片僅供參考", "※餐點內容會因季節而有所不同"],
        },
        {
          id: 10,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
          ticketName: ["大阪水上巴士"],
          ticketName2: ["大阪水上巴士"],
          ticketName3: ["大阪水上巴士"],
          ticketName4: ["大阪水上巴士"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_D.jpg",
          ],
          Doer: ["JR 環狀線「大阪城公園站」徒步約3分鐘"],
          time: [
            "11:00 / 11:45 / 12:30",
            "13:15 / 14:00 / 14:45 / 15:30 / 16:15",
          ],
          Http: " http://suijo-bus.osaka",
          Faci: [
            "大阪市中央區大阪城2番地前",
            "（水上巴士搭乘處，大阪城港乗船券賣場）",
          ],
          Calendar: [
            "運休時段為：平日運休2天、7/25下午時段",
            "1月中旬、2月上旬",
          ],
          price: "1,600",
          areaType: 2,
          content: "",
          content2: ["※春季（3月下旬 ～ 4月上旬）有特別價格。"],
        },
        {
          id: 11,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
          ticketName: ["大阪市立人居博物館", "「大阪生活今昔館」入場券"],
          ticketName2: ["大阪市立人居博物館", "「大阪生活今昔館」入場券"],
          ticketName3: ["大阪市立人居博物館", "「大阪生活今昔館」入場券"],
          ticketName4: ["大阪市立人居博物館", "「大阪生活今昔館」入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_C.jpg",
          ],
          Doer: ["JR 天滿橋站徒步約7分鐘"],
          time: ["10:00 ～ 17:00（最後入館16:30）"],
          Http: "http://konjyakukan.com/",
          Faci: ["大阪市北區天神橋6-4-20（大阪市立人情報中心8F）"],
          Calendar: ["星期二，年底年初（12/28 ～ 1/4）"],
          areaType: 2,
          price: "400",
        },
        // 滋賀・兵庫・和歌山
        {
          id: 12,
          activeId: 1,
          areaNAme: "兵庫",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
          ticketName: ["神戶布引香草園入場劵", "及纜車乘車劵"],
          ticketName2: ["神戶布引香草園入場劵", "及纜車乘車劵"],
          ticketName3: ["神戶布引香草園入場劵", "及纜車乘車劵"],
          ticketName4: ["神戶布引香草園入場劵", "及纜車乘車劵"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_E1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_F1.jpg",
          ],
          Doer: ["JR 新神戶站徒步5分鐘"],
          time: ["山麓站4F服務中心：9:30 ～ 16:45"],
          Http: "https://www.kobeherb.com/cn/",
          Faci: ["神戶市中央區北野町1-4-3", "（山麓站4樓諮詢台）"],
          Calendar: ["請參照官方網站"],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 13,
          activeId: 1,
          areaNAme: "兵庫",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
          ticketName: ["明石壽司「技之逸品」", "2000日圓優惠券"],
          ticketName2: ["明石壽司「技之逸品」", "2000日圓優惠券"],
          ticketName3: ["明石壽司「技之逸品」", "2000日圓優惠券"],
          ticketName4: ["明石壽司「技之逸品」", "2000日圓優惠券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_D.jpg",
          ],
          Doer: ["JR 明石站旁"],
          time: ["平日：9:00 ～ 19:00", "星期六日 節日：9:00 ～ 18:00"],
          Http: "https://www.yokoso-akashi.jp/sushi_coupon",
          Faci: [
            "兵庫縣明石市大明石町1-1-23",
            "（明石車站Akashi諮詢處 Piole西館）",
          ],
          Calendar: ["年底年初（12/31 ～ 1/1）"],
          areaType: 3,
          price: "2,000",
        },
        {
          id: 14,
          activeId: 1,
          areaNAme: "兵庫",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
          ticketName: ["兵庫縣明石市立", "天文科學館入館券"],
          ticketName2: ["兵庫縣明石市立天文科學館", "入館券"],
          ticketName3: ["兵庫縣明石市立天文科學館", "入館券"],
          ticketName4: ["兵庫縣明石市立天文科學館", "入館券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_D.jpg",
          ],
          Doer: ["JR 明石站徒歩約15分鐘", "山陽電車人丸前站徒歩約3分鐘"],
          time: [
            "明石介紹所",
            "平日：9:00 ～ 19:00",
            "假日：9:00 ～ 18:00",
            "明石市立天文科學館",
            " 9:30 ～ 17:00（最後入館 16:30）",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "兌換地點：兵庫縣明石市",
            "大明石町1-1-23piole明石西館",
            "設施地址：兵庫縣県明石市人丸町2-6",
          ],
          Calendar: ["年底年初（可能臨時休館）"],
          areaType: 3,
          price: "700",
        },
        {
          id: 15,
          areaNAme: "兵庫",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          ticketName2: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          ticketName3: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          ticketName4: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 明石站徒步約8分鐘"],
          time: [
            "（平日）",
            "明石乘船時間：9:00 ～ 17:00",
            "岩屋乘船時間：9:00 ～ 17:00",
            "（星期六日 節日）",
            "明石乘船時間：9:00 ～ 17:00",
            "岩屋乘船時間： 9:00 ～ 17:00",
            "※詳細時刻表請參照官方網站",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "明石乘船處：兵庫縣明石市本町2-10-1",
            "岩屋乗船場：兵庫縣淡路市岩屋925-27",
            "※可在任何一側搭乘JENOVA船",
          ],
          Calendar: ["天候不佳時將會停駛"],
          areaType: 3,
          price: "530",
        },
        {
          id: 16,
          activeId: 1,
          areaNAme: "滋賀",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
          ticketName: ["滋賀長濱浪漫景點", "實體護照", "（市內觀光設施通票）"],
          ticketName2: ["滋賀長濱浪漫景點實體護照", "（市內觀光設施通票）"],
          ticketName3: ["滋賀長濱浪漫景點實體護照", "（市內觀光設施通票）"],
          ticketName4: ["滋賀長濱浪漫景點實體護照", "（市內觀光設施通票）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_D.jpg",
          ],
          Doer: ["JR 長濱站內"],
          time: ["長濱站觀光服務處：9:15 ～ 16:45"],
          Http: "https://kitabiwako.jp/spot/spot_8966",
          Faci: ["滋賀縣長濱市北船町1−5", "（長濱站觀光諮詢處）"],
          Calendar: ["年底年初"],
          areaType: 3,
          price: "1,200",
        },
        {
          id: 17,
          activeId: 1,
          areaNAme: "滋賀",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
          ticketName: ["琵琶湖大津王子大飯店37樓", "湖景餐廳Biona", "午餐套餐"],
          ticketName2: [
            "琵琶湖大津王子大飯店",
            "37樓湖景餐廳Biona",
            "午餐套餐",
          ],
          ticketName3: [
            "琵琶湖大津王子大飯店",
            "37樓湖景餐廳Biona",
            "午餐套餐",
          ],
          ticketName4: [
            "琵琶湖大津王子大飯店",
            "37樓湖景餐廳Biona",
            "午餐套餐",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_E.jpg",
          ],
          Doer: ["JR 大津站可搭乘免費接駁車約10分鐘"],
          time: [
            "營業時間：11:30 ～ 15:00",
            "用餐時間：11:30 ～ 13:00",
            "13:30 ～ 15:00",
            "（限時90分鐘，2個時間段）",
          ],
          Http: "https://www.princehotels.cn/otsu/zh-hans/restaurants/lake-view-dining-biona/",
          Faci: [
            "滋賀縣大津市Niono濱4-7-7",
            "琵琶湖大津王子大飯店",
            "（37樓湖景餐廳Biona）",
          ],
          Calendar: ["無"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 18,
          activeId: 1,
          areaNAme: "滋賀",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
          ticketName: ["琵琶湖竹生島觀光遊覽船", "（彥根港～竹生島往返）"],
          ticketName2: ["琵琶湖竹生島觀光遊覽船", "（彥根港～竹生島往返）"],
          ticketName3: ["琵琶湖竹生島觀光遊覽船", "（彥根港～竹生島往返）"],
          ticketName4: ["琵琶湖竹生島觀光遊覽船", "（彥根港～竹生島往返）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_D.jpg",
          ],
          Doer: ["JR 彥根站搭乘免費接駁車約", "8分鐘於「彥根港」下車"],
          time: ["星期六 日 節日出航"],
          Http: "http://www.ohmitetudo.co.jp/marine/",
          Faci: ["滋賀縣彥根市松原町3755", "（彥根港乘船處）"],
          Calendar: ["平日，12/30 ～ 12/31"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 19,
          areaNAme: "滋賀",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
          ticketName: ["八幡山纜車往返劵"],
          ticketName2: ["八幡山纜車往返劵"],
          ticketName3: ["八幡山纜車往返劵"],
          ticketName4: ["八幡山纜車往返劵"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_D.jpg",
          ],
          Doer: [
            "JR 近江八幡站北口6號搭乘區",
            "前往長命寺巴士約7分",
            "至大衫町八幡山空中纜車處下車",
          ],
          time: ["9:00 ～ 17:00 （最后上山 16:30）"],
          Http: "http://www.ohmitetudo.co.jp/hachimanyama/open-price/index.html/",
          Faci: ["滋賀縣近江八幡市宮內町（山麓售票處）"],
          Calendar: ["無"],
          areaType: 3,
          price: "890",
        },
        {
          id: 20,
          areaNAme: "和歌山",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
          ticketName: ["和歌山串本海中公園", "水族館·展望塔入場券"],
          ticketName2: ["和歌山串本海中公園", "水族館·展望塔入場券"],
          ticketName3: ["和歌山串本海中公園水族館 ", "展望塔入場券"],
          ticketName4: ["和歌山串本海中公園水族館 ", "展望塔入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_E.jpg",
          ],
          Doer: ["JR 串本站搭乘免費接駁巴士約12分鐘"],
          time: ["9:00 ～ 16:30（服務窗口到 16:00）"],
          Http: "http://kushimoto.co.jp/chinese/",
          Faci: [
            "和歌山縣東牟婁郡串本町有田1157",
            "（和歌山串本海中公園入口處）",
          ],
          Calendar: ["無"],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 21,
          areaNAme: "和歌山",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
          copyright: "「DESIGNED BY EIJI MITOOKA + DON DESIGN ASSOCIATES」",
          ticketName: ["和歌山電鐵貴志川線", "1日乘車券"],
          ticketName2: ["和歌山電鐵貴志川線", "1日乘車券"],
          ticketName3: ["和歌山電鐵貴志川", "線1日乘車券"],
          ticketName4: ["和歌山電鐵貴志川", "線1日乘車券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_E.jpg",
          ],
          Doer: ["JR 和歌山站9號乘車區"],
          time: ["依車輛種類不同而各有差異"],
          Http: "http://www2.wakayama-dentetsu.co.jp/pdf/wakayama_chinese.pdf",
          Faci: ["和歌山縣和歌山市美園町5丁目2", "（和歌山電鐵和歌山站）"],
          Calendar: ["停駛日以及貓站長的上班日", "上班時間等請參照官方網站"],
          areaType: 3,
          price: "800",
        },
        // 山陽山陰
        {
          id: 22,
          areaNAme: "岡山",
          img: require("@/assets/images/allpass/erdao_A.jpg"),
          ticketName: ["兒島循環巴士乘車券及", "丹寧布手作體驗"],
          ticketName2: ["兒島循環巴士乘車券及", "丹寧布手作體驗"],
          ticketName3: ["兒島循環巴士乘車券及", "丹寧布手作體驗"],
          ticketName4: ["兒島循環巴士乘車券及", "丹寧布手作體驗"],
          tickeImg: [
            require("@/assets/images/allpass/erdao_A.jpg"),
            require("@/assets/images/allpass/erdao_B.jpg"),
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_D.jpg",
          ],
          Doer: ["JR 兒島站驗票口"],
          time: [
            "兌換時間：9:00 ～ 17:00",
            "丹寧布手作體驗：9:00 ～ 18:00",
            "（體驗時間：11:00 ～、14:00 ～）",
            "兒島循環巴士：星期五 六 日 節日",
            "FUREAI 巴士 / TOKOHAI巴士 （每天運行）",
          ],
          Http: "http://shimoden.net/rosen/kikaku/jeans.html",
          Faci: [
            "岡山縣倉敷市兒島車站前一丁目107番地",
            "（JR 兒島站巴士乘車處）",
          ],
          Calendar: [
            "丹寧布手作體驗休業日：年底年初",
            "兒島循環巴士休業日：平日一～四，年底年初",
          ],
          areaType: 4,
          price: "1,000",
        },
        {
          id: 23,
          areaNAme: "岡山",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
          ticketName: ["岡山格蘭比亞大酒店", "西點師傅主推聖代"],
          ticketName2: ["岡山格蘭比亞大酒店", "西點師傅主推聖代"],
          ticketName3: ["岡山格蘭比亞大酒店", "西點師傅主推聖代"],
          ticketName4: ["岡山格蘭比亞大酒店", "西點師傅主推聖代"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_D.jpg",
          ],
          Doer: ["JR 岡山站旁"],
          time: ["提供时间：12:00 ～ 17:00", "（酒店1樓大廳）"],
          Http: "https://granvia-oka.co.jp/cn/restaurant/#lum",
          Faci: ["岡山縣岡山市北區站元町1-5", "（1樓Lumière櫃檯）"],
          Calendar: ["無"],
          areaType: 4,
          price: "2,500",
        },
        {
          id: 24,
          areaNAme: "廣島",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
          ticketName: ["島田水產 廣島縣漁場介紹與", "海上遊覽嚴島神社"],
          ticketName2: ["島田水產 廣島縣漁場介紹", "與海上遊覽嚴島神社"],
          ticketName3: ["島田水產 廣島縣漁場介紹", "與海上遊覽嚴島神社"],
          ticketName4: ["島田水產 廣島縣漁場介紹", "與海上遊覽嚴島神社"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_D.jpg",
          ],
          Doer: ["山陽本線JR宮島口站徒歩約15分鐘", "（郵輪乘船處徒歩10分鐘）"],
          time: ["10:00 ～ 16:00"],
          Http: "https://shimadasuisan.com/kakigoya-cn-han.html",
          Faci: ["廣島縣廿日市市宮島口西1-2-6", "（kaki小屋售票處）"],
          Calendar: ["無"],
          areaType: 4,
          price: "2,200",
        },
        {
          id: 25,
          areaNAme: "廣島",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ticketName: ["瀨戶田周遊券"],
          ticketName2: ["瀨戶田周遊券"],
          ticketName3: ["瀨戶田周遊券"],
          ticketName4: ["瀨戶田周遊券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_C.jpg",
          ],
          Doer: ["JR 尾道站內"],
          time: ["尾道站觀光服務處：", "9:00 ～ 18:00"],
          Http: "https://www.ononavi.jp/fan/onotabi/guruseto/",
          Faci: ["廣島縣尾道市東禦所町1-1", "（尾道站觀光咨詢處）"],
          Calendar: ["12/29 ～ 1/3"],
          areaType: 4,
          price: "4,500",
        },
        {
          id: 26,
          areaNAme: "山口",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
          ticketName: ["山口縣防府市觀光地", "參觀通票"],
          ticketName2: ["山口縣防府市觀光地", "參觀通票"],
          ticketName3: ["山口縣防府市觀光地", "參觀通票"],
          ticketName4: ["山口縣防府市觀光地", "參觀通票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_E.jpg",
          ],
          Doer: ["JR 防府站內"],
          time: ["防府市觀光服務處：9:00 ～ 18:00"],
          Http: "https://visit-hofu.jp/ticket",
          Faci: ["山口縣防府市戎町1-1-1", "（JR 防府站 防府市觀光案內所）"],
          Calendar: ["无"],
          areaType: 4,
          price: "1,200",
        },
        {
          id: 27,
          areaNAme: "島根",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
          ticketName: ["日本庭園由志園入場券"],
          ticketName2: ["日本庭園由志園入場券"],
          ticketName3: ["日本庭園由志園入場券"],
          ticketName4: ["日本庭園由志園入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_E.jpg",
          ],
          Doer: ["JR 松江站6號乘車區", "搭乘巴士約50分鐘"],
          time: ["10:00 ～ 17:00（最後入園16：30）"],
          Http: "https://www.yuushien.com/",
          Faci: ["島根縣松江市八束町", "波入1260-2 （售票處）"],
          Calendar: ["12/30 ～ 12/31"],
          areaType: 4,
          price: "1,000",
        },
        // 石川
        {
          id: 28,
          areaNAme: "石川",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
          ticketName: ["加賀傳統工藝鄉", "湯之國之森入村券"],
          ticketName2: ["加賀傳統工藝鄉", "湯之國之森入村券"],
          ticketName3: ["加賀傳統工藝鄉", "湯之國之森入村券"],
          ticketName4: ["加賀傳統工藝鄉", "湯之國之森入村券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_D.jpg",
          ],
          Doer: ["JR 加賀溫泉站搭乘 CANBUS 環山線", "約40分鐘"],
          time: ["9:00 ～ 16:30（最後入圓 15:30）"],
          Http: "https://www.yunokuni.jp/mori/?lang=tw",
          Faci: ["石川縣小松市栗津溫泉Na3-3 （售票處）"],
          Calendar: ["請參照官方網站"],
          areaType: 5,
          price: "550",
        },
        {
          id: 29,
          areaNAme: "石川",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
          ticketName: ["加賀周遊巴士「CANBUS」", "1日周遊券"],
          ticketName2: ["加賀周遊巴士「CANBUS」", "1日周遊券"],
          ticketName3: ["加賀周遊巴士「CANBUS」", "1日周遊券"],
          ticketName4: ["加賀周遊巴士「CANBUS」", "1日周遊券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_D.jpg",
          ],
          Doer: ["JR 加賀溫泉站南出口「MACHIDUKURI加賀周遊券販售處」"],
          time: ["MACHIDUKURI加賀周遊券販售處：", "8:30 ～ 18:00"],
          Http: "http://www.kaga-canbus.jp/chinese.php",
          Faci: ["石川縣加賀市作見町RI 1-3", "（Machizukuri加賀周遊券販售處）"],
          Calendar: ["無"],
          areaType: 5,
          price: "1,100",
        },
      ],
      md3: [
        {
          id: 1,
          name: "所有地区",
          num: 29,
          areaType: 0,
        },
        {
          id: 2,
          name: "大阪",
          areaType: 2,
        },
        {
          id: 3,
          name: "京都",
          areaType: 1,
        },
      ],
      md4: [
        {
          id: 4,
          name: "石川",
          areaType: 5,
        },
      ],
      md5: [
        {
          id: 5,
          name: "滋贺·兵库·和歌山",
          areaType: 3,
        },
      ],
      md7: [
        {
          id: 6,
          name: "山阴山阳",
          areaType: 4,
        },
      ],
    };
  },
  created() {
    this.showArea();
  },
  mounted() {
    window.addEventListener("resize", this.setbanH, false);
  },
  destroyed() {
    window.removeEventListener("resize", this.setbanH, false);
  },
  methods: {
    // 手指触摸滑动事件
    touchstart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    touchmove(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX >= 150) {
        // 右滑触发
        this.drawer = true;
      }
    },
    touchmove1(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX <= -100) {
        // 右滑触发
        this.drawer = false;
      }
    },
    touchmove2(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX <= -100) {
        // 右滑触发
        this.innerDrawer = false;
      }
    },
    loading() {
      this.showBtn = true;
      this.$forceUpdate();
    },
    setbanH() {
      this.$nextTick(() => {
        this.banH = `${this.$refs.imgH[0].height}px`;
      });
    },
    change(item) {
      this.active = item.id;
      if (this.active === 1) {
        this.ticketDescribe = "观光设施共通券(29项设施)";
        this.planName = "关西广域周游券5天套票";
        this.planPrice = "27,700";
        this.planName2 = "关西广岛周游券5天套票";
        this.planPrice2 = "32,000";
        this.planName3 = "JR西日本全地区铁路周游券";
        this.planPrice3 = "39,000";
        this.md3[0].num = 29;
        this.showList = this.scenicSpotArr;
        let array = {
          id: 15,
          areaNAme: "兵库",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          ticketName2: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          ticketName3: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          ticketName4: ["JENOVA号淡路岛", "（明石⇔岩屋）单程船票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 明石站徒步约8分钟"],
          time: [
            "（平日）",
            "明石乘船时间：9:00 ～ 17:00",
            "岩屋乘船时间：9:00 ～ 17:00",
            "（星期六日 节日）",
            "明石乘船时间：9:00 ～ 17:00",
            "岩屋乘船时间： 9:00 ～ 17:00",
            "※详细时刻表请参照官方网站",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "明石乘船处：兵库県明石市本町2-10-1",
            "岩屋乘船处：兵库県淡路市岩屋925-27",
            "※可在任何一侧搭乘JENOVA船",
          ],
          Calendar: ["天候不佳时将会停驶"],
          areaType: 3,
          price: "530",
          content: "",
          content2: "",
        };
        this.id15 = this.showList.findIndex((item) => {
          if (item.id === 14) {
            return true;
          }
        });
        this.showList.splice(14, 0, array);
      } else {
        this.ticketDescribe = "观光设施共通券(16项设施)";
        this.planName = "关西地区铁路周游券3天套票方案";
        this.planName_small = "关西地区铁路周游券3天套票方案";
        this.planPrice = "19,300";
        this.planName2 = "关西广域地区铁路周游券5天套票方案";
        this.planPrice2 = "22,700";
        this.planName2 = "关西广域地区铁路周游券5天套票方案";
        this.planName3 = "";
        this.planPrice3 = "";
        this.md3[0].num = 16;
        this.showList = this.scenicSpotArr;
        let id15 = this.showList.findIndex((item) => {
          if (item.id === 15) {
            return true;
          }
        });
        this.showList.splice(id15, 1);
      }
      this.activeAreaArr = []; //清除已选中的地区id
      this.activeArr = [1]; //切换探游券时 默认全部
    },
    openSmall(item) {
      this.ticketDetials = item;
      this.innerDrawer = true;
    },
    showArea() {
      this.showList = this.scenicSpotArr;
    },
    activeArea(item) {
      let index = 0;
      this.showList = [];
      if (item.areaType === 0) {
        // 选择了所有
        this.activeArr = [1]; //所有
        this.activeAreaArr = [];
        this.active === 1
          ? (this.showList = this.scenicSpotArr)
          : (this.showList = this.scenicSpotArr); //判断是否西日本
        return;
      }
      if (item.areaType !== 0) {
        // 没有选择全部地区并且选的是西日本
        let alldelIndex = this.checkArray(this.activeArr, 1); //找到复选地区中所有地区的下标
        alldelIndex !== -1 ? this.activeArr.splice(alldelIndex, 1) : ""; //如果有所有地区的下标就删除
        let activeArrDelIndex = this.checkArray(this.activeArr, item.id); //查找复选地区是否已经存在
        let activeAreaArrDelIndex = this.checkArray(
          this.activeAreaArr,
          item.areaType
        ); //查找复选地区是否已经存在
        activeArrDelIndex === -1
          ? this.activeArr.push(item.id)
          : this.activeArr.splice(activeArrDelIndex, 1); //如果目前地区存在就删除，不然就加入
        activeAreaArrDelIndex === -1
          ? this.activeAreaArr.push(item.areaType)
          : this.activeAreaArr.splice(activeAreaArrDelIndex, 1); //查找复选地区是否已经存在
        this.scenicSpotArr.forEach((item1) => {
          if (item.areaType === item1.areaType) {
            // 去重
            let newArr = [];
            for (let i = 0; i < this.activeArr.length; i++) {
              newArr.indexOf(this.activeArr[i]) == -1
                ? newArr.push(this.activeArr[i])
                : "";
            }
            this.activeArr = newArr;
          }
        });
        this.activeAreaArr = this.unique(this.activeAreaArr); //景点属性去重
        this.scenicSpotArr.forEach((item11) => {
          this.activeAreaArr.forEach((item12) => {
            item12 == item11.areaType ? this.showList.push(item11) : "";
            item12 == item11.areaType ? console.log(item11) : "";
          });
        });
      } else {
        this.activeArr = [1];
        this.showList = this.scenicSpotArr;
      }
    },
    unique(arr) {
      var result = [];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            j = ++i;
          }
        }
        result.push(arr[i]);
      }
      return result;
    },
    checkArray(arr, target) {
      return arr.indexOf(target);
    },
    handleClose(done) {
      done();
    },
    splitBatName(str) {
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/index2.scss";
.touch {
  touch-action: pan-y !important;
}
.loadImg {
  width: 100%;
  height: auto;
}
.main-package {
  z-index: 0 !important;
}
::v-deep .el-drawer__body {
  overflow: auto;
}
::v-deep .el-drawer__container ::-webkit-scrollbar {
  display: none;
}
::v-deep .el-carousel__indicator--horizontal {
  padding: 0 4px;
}

::v-deep .el-drawer {
  width: 100% !important;
}

::v-deep .el-drawer__header {
  display: block;
  color: rgb(255, 152, 0);
  padding: 14px 16px;
  margin-bottom: 0;
  background-color: rgb(255, 237, 147);
  position: relative;
  height: 27px;
  line-height: 27px;
}

::v-deep .el-icon-close:before {
  content: "\e6de";
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #fb7b05;
  border-radius: 20px;
}

::v-deep .el-drawer__close-btn {
  border: 0;
  outline: none;
}
::v-deep .el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 7px;
  height: 7px;
  background-color: #bcbfbf;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
}

::v-deep .el-carousel__indicator.is-active button {
  width: 7px;
  height: 7px;
  background-color: #ff8033;
  border-radius: 50%;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 360px) {
  ::v-deep .el-carousel__indicators--horizontal {
    bottom: 19%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>