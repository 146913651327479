<template>
  <div id="main">
    <!-- 顶部 -->
    <div class="main-nav-box">
      <div class="main-nav">
        <a href="/home">
          <img class="PC_home_icon" :src="PC_home" lazy />
        </a>
        <div class="small-language-box1" @click.stop="isShow = !isShow">
          <span class="small-language2">Language(繁體中文)</span>
          <img class="hamburger-img" :src="PC_line" lazy />
        </div>
        <div class="hamburger-mask" v-if="isShow">
          <div class="main-smallnav">
            <a class="app-menu-list" href="/exploreallpass/zh/">
              <div class="smallnav-select-item"><span class="main-nav-content">简体中文</span>
              </div>
            </a>
            <a class="app-menu-list" href="/exploreallpass/zh_tw/">
              <div class="smallnav-select-item"><span class="main-nav-content  smallnav-select-item-active">繁體中文</span></div>
            </a>
            <a class="app-menu-list" href="/exploreallpass/en/">
              <div class="smallnav-select-item"><span class="main-nav-content">ENGLISH</span></div>
            </a>
            <a class="app-menu-list" href="/exploreallpass/ko/">
              <div class="smallnav-select-item"><span class="main-nav-content">한국어</span></div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <div class="main-swiper">
      <div>
        <el-carousel class="bannerImg-box" trigger="click" :interval="7000">
          <el-carousel-item v-for="(item,index) in bannerImg" :key="index">
            <img :src="item" class="slide-img1" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 有效期探游卷 -->
    <div class="main-travel ">
      <div class="train-box">
        <div class="app-container">
          <div class="col-md-12 travel-item-box">
            <img class="lazy tracks-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-1.png" alt="">
            <div class="travel-item-md6" v-for="(item,i) in ticketArr" :key="i" @click="change(item)">
              <div :class="[active===item.id ? 'travel-item1' : 'travel-item']">
                <img class="travel-item-img" :src="item.img" lazy />
                <p class="travel-right">有效日
                  <span class="travel-right-day">
                    <span :style="{'color':item.fontcolor}">{{item.validityDay}}</span>&nbsp;天</span>
                </p>
                <!-- <p class="travel-title">{{item.ticketName}}</p> -->
                <p class="travel-detail" v-for="(item ,index) in splitBatName(item.describe)" :key="index">{{item}}</p>
                <p class="travel-price" :style="{'color':item.fontcolor}">￥{{item.price}}</p>
              </div>
            </div>
          </div>
        </div>
        <img class="train-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-1.png" lazy />
        <img class="train-img2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-1.png" lazy />
      </div>
    </div>
    <!-- 套餐内容 -->
    <div class="main-package">
      <div class="app-container">
        <div class="package-imgBox">
          <img class="package-train-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-2.png" lazy />
          <img class="package-point-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-1.png" lazy />
        </div>
        <div class="col-md-12  package-content-box">
          <div class="package-content flex-arround">
            <img class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy />
            <p class="package-title">ALL PASS 套餐內容</p>
            <img class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy />
          </div>
        </div>
        <div class="col-md-12">
          <div class="package-riding-content">
            <div class="riding-box">
              <div class="riding-left">
                <img class="riding-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/riding-2.png" lazy />
                <img class="riding-copyright" :src="rid_copy" lazy />
              </div>
              <div class="riding-right">
                <div class="right-title-box">
                  <p class="riding-right-title">JR乘車券</p>
                  <p class="riding-right-title2">能夠搭乘指定座位</p>
                </div>
                <p class="riding-right-title3">關西特快列車HARUKA單程券坐票<br>(關西機場站-京都站)</p>
                <img :src="ticket_zhu2" alt="" style="width:362px ;" lazy />
              </div>
            </div>
            <img :src="ticket_zhu" style="width: 76%;margin-left: 20px;margin-top: 5px;" lazy />
            <div class="riding-bottom" :class="[active===2 ? 'riding-bottom2' : '']">
              <div class="riding-bottom-1" :class="[active===2 ? 'riding-bottom-1-1' : '']">
                <p class="riding-bottom-t" :class="[active===2 ? 'riding-bottom-t1' : '']">{{planName}}</p>
                <p class="riding-bottom-p">￥{{planPrice}}</p>
              </div>
              <div class="riding-bottom-2" v-if="planName2 && planPrice2" :class="[active===2 ? 'riding-bottom-2-1' : '']">
                <p class="riding-bottom-t">{{planName2}}</p>
                <p class="riding-bottom-p">￥{{planPrice2}}</p>
              </div>
              <div class="riding-bottom-3" v-if="planName3 && planPrice3">
                <p class="riding-bottom-t">{{planName3}}</p>
                <p class="riding-bottom-p">￥{{planPrice3}}</p>
              </div>
              <!-- <div class="riding-bottom-4" :class="[active===2 ? 'riding-bottom-42' : '']">
                            <p>※仅一次有效</p>
                            <p>※也能够于天王寺•新大阪站下车</p>
                        </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="package-universal-content">
            <div class="universal-left">
              <p class="universal-left-1">日本環球影城1天入場券</p>
              <!-- <p class="universal-left-2">无法想象的感动在影城等待您！</p> -->
              <div class="universal-right">
                <p class="universal-left-3">
                  <span>除了榮獲主題樂園業界中最高榮譽「Thea獎」的全球首創<br>*瑪利歐園區及「瑪利歐賽車～ 庫巴的挑戰書～™」，</span>
                  <span>還有哈利波特的魔法世界™、小小兵等包羅萬象的大規模遊樂設施，讓大人小孩都能樂在其中！</span>
                  <span>在充滿感動與興奮的園區遊玩，讓您歡樂無限！</span>
                </p>
                <img class="lazy universal-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/universal-zh1.jpg" data-url="https://qbcdn.jtrchina.com/tripelletexploreallpass/universal-zh1.jpg" alt="">
              </div>
              <div class="universal-left-4">
                <p>*（合同會社）日本環球影城以任天堂品牌角色及遊戲世界為主題的遊樂設施的調查結果</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 观光设施共通劵 -->
        <div class="col-md-12 " style="display: flex;justify-content: center;">
          <div class="facilities-content ">
            <div class="facilities-borderWhite">
              <div class="facilities-box flex-arround">
                <img class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy />
                <p class="facilities-title">{{ticketDescribe}}</p>
                <img class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy />
              </div>
              <div class="facilities-attention ">
                <p>1.各設施有可能臨時休業的形況，當日出發遊玩前請務必確認官網最新消息及營業時間。</p>
                <p>2.欲前往下個遊玩景點，需至少間隔2小時，才能掃碼入場。</p>
                <p>3.同一個設施不可重複入場。</p>
              </div>
              <div class="row area-box">
                <div class="area-md3" v-for="(item,i) in md3" :key="i" @click="activeArea(item)">
                  <div class="all-area" :class="[activeArr.includes(item.id)? 'area-active' : '']">
                    {{item.name}}
                  </div>
                </div>
                <div class="area-md3" v-show="active === 1" v-for="(item,i) in md4" :key="i" @click="activeArea(item)">
                  <div class="all-area" :class="[activeArr.includes(item.id)? 'area-active' : '']">
                    {{item.name}}
                  </div>
                </div>
              </div>
              <div class="row area-box2">
                <div class="area-md5" v-for="(item,i) in md5" :key="i" @click="activeArea(item)">
                  <div class="area2 " :style="{backgroundColor:(activeArr.includes(item.id)?'#ff7b00':'#fff'),color:(activeArr.includes(item.id)?'#fff':'#ff7b00')}">
                    {{item.name}}
                  </div>
                </div>
                <div class="area-md7" v-show="active===1" v-for="(item,i) in md7" :key="i" @click="activeArea(item)">
                  <div class="area3" :style="{backgroundColor:(activeArr.includes(item.id)?'#ff7b00':'#fff'),color:(activeArr.includes(item.id)?'#fff':'#ff7b00')}">
                    {{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <img class="train-footer-img1" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-footer.png" lazy />
          <img class="train-footer-img2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-footer.png" lazy />
          <img class="train-footer-img3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-2.png" lazy />
        </div>
        <!-- 地区模块 -->
        <div class="facilities-tracks2">
          <img class="tracks-2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" lazy />
          <imge class="tracks-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(6))?'none':'block')}" lazy />
          <img class="tracks-6" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-6.png" alt="" v-show="active===1" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}" lazy />
        </div>
        <div class="col-md-12 " style="display:flex;flex-direction: column;">
          <div class="facilities-detail">
            <div class="app-container">
              <img class="lazy point-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-3.png" alt="">
              <img class="tracks-2" :style="{display:(activeArr.includes(4)?'none':'block')}" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="">
              <img class="lazy point-4" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-4.png" alt="" :style="{display:(activeArr.includes(4)?'none':'block')}">
              <img class="lazy point-5" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-5.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="lazy point-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/cart.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="tracks-6" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-6.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="lazy tracks-point-2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-2.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="tracks-2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="" :style="{display:(activeArr.includes(2)?'none':'block')}">
              <img class="lazy tracks-fox" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-fox.png" alt="">
              <img class="tracks-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(6))?'none':'block')}">
              <img class="lazy tracks-point-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-3.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="lazy train-yellow" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-yellow.png" alt="" :style="{display:((activeArr.includes(1))?'block':'none')}">
              <img class="lazy tracks-door" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/door.png" alt="" :style="{display:((activeArr.includes(1))?'block':'none')}">
              <img class="tracks-middle" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-middle.png" alt="" :style="{display:((activeArr.includes(1))?'blobk':'none')}">
              <img class="tracks-middle2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-middle.png" alt="" :style="{display:((activeArr.includes(1))?'blobk':'none')}">
              <div class="detail-item-list col-md-12">
                <div class="col-md-4" v-for="(item,i) in showList" :key="i" @click="open(item)">
                  <div class="detail-item-box" v-if="active === 1">
                    <div class="detail-item" id="detail-item">
                      <img class="detail-item-img" :src="item.img" :data-url="item.img" lazy />
                      <div class="detail-item-areaName">
                        <p v-for="(item ,index) in splitBatName(item.ticketName)" :key="index">{{item}}</p>
                      </div>
                    </div>
                    <span class="detail-item-productName">{{item.content}}</span>
                  </div>
                  <div class="detail-item-box" v-else v-show="active === 2 && item.activeId === 1">
                    <div class="detail-item" id="detail-item">
                      <img class="detail-item-img" :src="item.img" :data-url="item.img" lazy />
                      <div class="detail-item-areaName">
                        <p v-for="(item ,index) in splitBatName(item.ticketName)" :key="index">{{item}}</p>
                      </div>
                    </div>
                    <span class="detail-item-productName">{{item.content}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" col-md-12 detail-item-attentionBox">
            <p class="detail-item-attention">注意事項</p>
            <p class="detail-item-attention2">*ALL PASS的有效期為標題所示，與區域周遊券的有效期無關。</p>
            <p class="detail-item-attention2">*購買了關西特快列車HARUKA列車的單程票券以及區域周遊券的顧客請務必在ALL PASS的有效期間的最終有效日之前進行兌換。</p>
            <p class="detail-item-attention2">*如果在有效期間的最終有效日未能進行兌換的話則無法使用。</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出 -->
    <div class="backdrop" v-if='showDialog'>
      <div class="backdrop-content">
        <img class="lazy glyphicon" :src="close" alt="" @click="close2()">
        <div class="backdrop-swiper">
          <el-carousel class="banner" trigger="click" height="281px" :interval="3000">
            <el-carousel-item v-for="(item,index) in ticketDetials.tickeImg" :key="index">
              <img class="backdrop-content-img" :src="item" lazy />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="backdrop-content-box">
          <p class="travel-copyright" v-if="ticketDetials.copyright">{{ticketDetials.copyright}}</p>
          <p class="travel-facilityName" :style="{marginTop:ticketDetials.id===21?'0px':'16px'}">
            {{ticketDetials.areaNAme}}</p>
          <p class="content-name" v-for="(item ,index) in splitBatName(ticketDetials.ticketName2)" :key="index">{{item}}
          </p>
          <div class="backdrop-travel-cartBox">
            <img class="backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/facility_area.png" lazy />
            <div class="" style="display: flex;flex-direction: column;">
              <span class="backdrop-travel-cartName"><a :href="ticketDetials.Http" target=_blank>{{ticketDetials.Http}}</a></span>
            </div>
            <div class="" style="display: flex;flex-direction: column;" v-if="ticketDetials.Http3">
              <span class="backdrop-travel-cartName"><a href="/public/gurumeguri_map2022.pdf" target=_blank>{{ticketDetials.Http4}}</a></span>
              <span class="backdrop-travel-cartName"><a href="/public/gurumeguri2022.pdf" target=_blank>{{ticketDetials.Http3}}</a></span>
            </div>
            <span class="backdrop-travel-cartName" style="margin-left: 0;color: #000;">{{ticketDetials.Http2}}</span>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-facilities.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.Faci)" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-cart.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.Doer)" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-name.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.time)" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-calendar.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.Calendar)" :key="index">{{item}}
              </p>
            </div>
          </div>
          <p class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-price.png" alt="">
            <span class="backdrop-travel-cartName">￥{{ticketDetials.price}}</span>
          </p>
          <div class="backdrop-travel-cartBox" v-if="ticketDetials.content2">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.content2)" :key="index">{{item}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      PC_home: require("@/assets/images/allpass/PC-home.png"),
      PC_line: require("@/assets/images/allpass/PC_line.png"),
      ticket_zhu2: require("@/assets/images/allpass/ticket_tw1.png"),
      ticket_zhu: require("@/assets/images/allpass/ticket_tw2.png"),
      close: require("@/assets/images/allpass/close.png"),
      rid_copy: require("@/assets/images/allpass/copyright.png"),
      loadImg: require("@/assets/images/allpass/loading.png"),
      active: 1,
      animateTime: 3000, // 要和 css 的过渡一致
      activeAreaName: 1,
      ticketDescribe: "觀光設施共通券(29項設施)",
      planName: "關西廣域周遊券5天套票",
      planName_small: "關西廣域周遊券5天套票",
      planPrice: "27,700",
      planName2: "關西廣島周遊券5天套票",
      planName2_small: "關西廣島周遊券5天套餐",
      planPrice2: "32,000",
      planName3: "JR西日本全地區鐵路周遊券",
      planPrice3: "39,000",
      ticketDetials: {},
      showDialog: false,
      drawer: false,
      innerDrawer: false,
      startX: "",
      startY: "",
      moveY: "",
      moveX: "",
      startTime: "",
      activeArr: [1],
      activeAreaArr: [],
      ticketArr: [
        {
          id: 1,
          type: 1,
          fontcolor: "#F98304",
          img: require("@/assets/images/allpass/travel-t.png"),
          ticketName: "西日本探遊券",
          validityDay: 4,
          price: "21,000~",
          describe: [
            "JR乘車券+",
            "觀光設施共通券29設施+",
            "日本環球影城1日周遊券",
          ],
        },
        {
          id: 2,
          type: 2,
          fontcolor: "#F4B22D",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-21.png",
          ticketName: "關西探遊券",
          validityDay: 3,
          price: "16,000~",
          describe: [
            "JR乘車券+",
            "觀光設施共通券16設施+",
            "日本環球影城1日周遊券",
          ],
        },
      ],
      bannerImg: [
        require("@/assets/images/allpass/pc_banner.png"),
        require("@/assets/images/allpass/PC_TW.png"),
      ],
      bannerMb: [
        "https://qbcdn.jtrchina.com/tripelletexploreallpass/banner/mb_banner.png",
        "../img/MB_CH.png",
      ],
      /*
                西日本数组
                areaType 1京都 2大阪 3滋賀・兵庫・和歌山 4山陽山陰 5石川
                */
      scenicSpotArr: [
        // 京都
        {
          id: 1,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
          ticketName: ["京都鐵道博物館入場券"],
          ticketName2: ["京都鐵道博物館入場券"],
          ticketName3: ["京都鐵道博物館入場券"],
          ticketName4: ["京都鐵道博物館入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_D.jpg",
          ],
          Doer: ["JR 梅小路京都西站徒步約2分鐘"],
          time: ["10:00 ～ 17:00（最後入館16:30）"],
          Http: "http://www.kyotorailwaymuseum.jp/sc/ ",
          Faci: ["京都市下京區觀喜寺町", "（京都鐵道博物館售票處）"],
          Calendar: ["星期三，年底年初（可能臨時休館）"],
          areaType: 1,
          price: "1,200",
        },
        {
          id: 2,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
          ticketName: ["東映太秦映畫村入場券"],
          ticketName2: ["東映太秦映畫村入場券"],
          ticketName3: ["東映太秦映畫村入場券"],
          ticketName4: ["東映太秦映畫村入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_D.jpg",
          ],
          Doer: ["JR 花園站徒歩約13分鐘"],
          time: [
            "10:00 ～ 17:00",
            "※可能有臨時休館的情況發生， ",
            "營業時間請參照官方網站。",
          ],
          Http: "http://www.toei-eigamura.com/global/cn/ ",
          Faci: ["京都市右京區太秦東峰岡町10", "（東映太秦映畫村售票處）"],
          Calendar: ["請參照官方網站"],
          areaType: 1,
          price: "2,400",
        },
        {
          id: 3,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
          ticketName: ["嵐山美食優惠券", "嵐電1日通票"],
          ticketName2: ["嵐山美食優惠券", "嵐電1日通票"],
          ticketName3: ["嵐山美食優惠券", "嵐電1日通票"],
          ticketName4: ["嵐山美食優惠券", "嵐電1日通票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_D.jpg",
          ],
          Doer: ["JR 嵯峨嵐山站徒步約8分鐘"],
          time: ["嵐山電車「嵐山站Information」", "9:00 ～ 18:00"],
          Http4: "https://www.tripellet.com/gurumeguri_map2022.pdf",
          Http3: "https://www.tripellet.com/gurumeguri2022.pdf",
          Faci: [
            "京都市右京區嵯峨",
            "天龍寺造路町20-2",
            "（嵐電嵐山站諮詢處）",
          ],
          Calendar: ["※各店家的營業時間，", "請參閱優惠劵上。"],
          areaType: 1,
          price: "1,000",
        },
        {
          id: 4,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
          ticketName: ["Sky Hop Bus京都", "雙層觀光巴士（當日劵）"],
          ticketName2: ["Sky Hop Bus京都", "雙層觀光巴士（當日劵）"],
          ticketName3: ["Sky Hop Bus京都", "雙層觀光巴士（當日劵）"],
          ticketName4: ["Sky Hop Bus京都", "雙層觀光巴士（當日劵）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_C.jpg",
          ],
          Doer: ["JR 京都站烏丸口徒步約1分鐘"],
          time: ["請參照官方網站（可能會臨時停開）"],
          Http: "https://skyhopbus.com/kyoto/ ",
          Faci: [
            "京都市下京區烏丸通塩小路下東塩小路町",
            "（京都觀定期觀光巴士乘車處）",
          ],
          Calendar: ["請參照官方網站"],
          areaType: 1,
          price: "2,500",
        },
        {
          id: 5,
          activeId: 1,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
          ticketName: ["京都和服體驗夢館", "五條店和服和浴衣", "租借方案"],
          ticketName2: ["京都和服體驗夢館五條店和服和浴衣租借方案"],
          ticketName3: ["京都和服體驗夢館五條店和服和浴衣租借方案"],
          ticketName4: ["京都和服體驗夢館五條店和服和浴衣租借方案"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_D.jpg",
          ],
          Doer: ["JR 京都站搭地下鐵烏丸線至五條站", "徒步約3分鐘"],
          time: ["10:00 ～ 17:30（最後入館 16:00）"],
          Http: "https://cn-kyoto.yumeyakata.com/itiran-gojo/",
          Faci: ["京都市下京區塩竈町353"],
          Calendar: ["年底年初（12/31 ～ 1/3）"],
          areaType: 1,
          price: "3,850",
        },

        {
          id: 6,
          areaNAme: "京都",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
          ticketName: ["天橋立伊根周遊券2日券", "（巴士・船・電纜車）"],
          ticketName2: ["天橋立伊根周遊券2日券", "（巴士・船・電纜車）"],
          ticketName3: ["天橋立伊根周遊券2日券", "（巴士・船・電纜車）"],
          ticketName4: ["天橋立伊根周遊券2日券", "（巴士・船・電纜車）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_D.jpg",
          ],
          Doer: ["京都丹後鐵路天橋立站內"],
          time: ["天橋立Terminal Center", "內觀光服務處：10:00 ～ 16:30"],
          Http: "https://www.tankai.jp/tourist_tickets/",
          Faci: ["宮津市字文珠314-2", "（天橋立車站內「天橋立觀光協會中心」）"],
          Calendar: ["無"],
          areaType: 1,
          price: "2,600",
        },
        // 大阪
        {
          id: 7,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
          ticketName: ["梅田藍天大廈空中庭園", "眺望臺入場券"],
          ticketName2: ["梅田藍天大廈空中庭園", "眺望臺入場券"],
          ticketName3: ["梅田藍天大廈空中庭園", "眺望臺入場券"],
          ticketName4: ["梅田藍天大廈空中庭園", "眺望臺入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_D.jpg",
          ],
          Doer: ["JR 大阪站徒步約7分鐘"],
          time: ["9:30 ～ 22:30（最後入場22:00）"],
          Http: "https://www.skybldg.co.jp/cn/",
          Faci: ["大阪市北區大澱中1-1-88梅田藍天大廈", "（39樓售票櫃台）"],
          Calendar: ["無"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 8,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
          ticketName: ["日本最高樓—阿倍野「HARUKAS 300」展望臺"],
          ticketName2: ["日本最高樓—阿倍野「HARUKAS 300」展望臺"],
          ticketName3: ["日本最高樓—阿倍野「HARUKAS 300」展望臺"],
          ticketName4: ["日本最高樓—阿倍野「HARUKAS 300」展望臺"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_D.jpg",
          ],
          Doer: ["JR 天王寺站旁"],
          time: ["9:00 ～ 22:00（最後入場 19:30）"],
          Http: "https://www.abenoharukas-300.jp/zh/",
          Faci: ["大阪市阿倍野區阿倍野筋1-1-43", "（16層服務台）"],
          Calendar: ["無"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 9,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
          ticketName: ["大阪格蘭比亞大酒店", "甜點交響曲"],
          ticketName2: ["大阪格蘭比亞大酒店甜點交響曲"],
          ticketName3: ["大阪格蘭比亞大酒店", "甜點交響曲"],
          ticketName4: ["大阪格蘭比亞大酒店", "甜點交響曲"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_E1.jpg",
          ],
          Doer: ["連接著JR 大阪站 中央口"],
          time: [
            "甜點提供時間：11:00 ~ 16:00",
            "平日：10:00 ~ 20:00（Lo19:30）",
            "星期六：9:30 ~ 20:00（Lo19:30）",
            "星期日 節日：9:30 ~18:00（Lo17:30）",
          ],
          Http: "https://www.hotelgranviaosaka.jp/zh/dining/lobby-lounge/",
          Faci: ["大阪市北區梅田3丁目1-1（1樓櫃檯）"],
          Calendar: ["無"],
          areaType: 2,
          price: "2,300",
          content: "",
          content2: ["※圖片僅供參考", "※餐點內容會因季節而有所不同"],
        },
        {
          id: 10,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
          ticketName: ["大阪水上巴士"],
          ticketName2: ["大阪水上巴士"],
          ticketName3: ["大阪水上巴士"],
          ticketName4: ["大阪水上巴士"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_D.jpg",
          ],
          Doer: ["JR 環狀線「大阪城公園站」徒步約3分鐘"],
          time: [
            "11:00 / 11:45 / 12:30",
            "13:15 / 14:00 / 14:45 / 15:30 / 16:15",
          ],
          Http: " http://suijo-bus.osaka",
          Faci: [
            "大阪市中央區大阪城2番地前",
            "（水上巴士搭乘處，大阪城港乗船券賣場）",
          ],
          Calendar: [
            "運休時段為：平日運休2天、7/25下午時段",
            "1月中旬、2月上旬",
          ],
          price: "1,600",
          areaType: 2,
          content: "",
          content2: ["※春季（3月下旬 ～ 4月上旬）有特別價格。"],
        },
        {
          id: 11,
          activeId: 1,
          areaNAme: "大阪",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
          ticketName: ["大阪市立人居博物館", "「大阪生活今昔館」入場券"],
          ticketName2: ["大阪市立人居博物館", "「大阪生活今昔館」入場券"],
          ticketName3: ["大阪市立人居博物館", "「大阪生活今昔館」入場券"],
          ticketName4: ["大阪市立人居博物館", "「大阪生活今昔館」入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_C.jpg",
          ],
          Doer: ["JR 天滿橋站徒步約7分鐘"],
          time: ["10:00 ～ 17:00（最後入館16:30）"],
          Http: "http://konjyakukan.com/",
          Faci: ["大阪市北區天神橋6-4-20（大阪市立人情報中心8F）"],
          Calendar: ["星期二，年底年初（12/28 ～ 1/4）"],
          areaType: 2,
          price: "400",
        },
        // 滋賀・兵庫・和歌山
        {
          id: 12,
          activeId: 1,
          areaNAme: "兵庫",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
          ticketName: ["神戶布引香草園入場劵", "及纜車乘車劵"],
          ticketName2: ["神戶布引香草園入場劵", "及纜車乘車劵"],
          ticketName3: ["神戶布引香草園入場劵", "及纜車乘車劵"],
          ticketName4: ["神戶布引香草園入場劵", "及纜車乘車劵"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_E1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_F1.jpg",
          ],
          Doer: ["JR 新神戶站徒步5分鐘"],
          time: ["山麓站4F服務中心：9:30 ～ 16:45"],
          Http: "https://www.kobeherb.com/cn/",
          Faci: ["神戶市中央區北野町1-4-3", "（山麓站4樓諮詢台）"],
          Calendar: ["請參照官方網站"],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 13,
          activeId: 1,
          areaNAme: "兵庫",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
          ticketName: ["明石壽司「技之逸品」", "2000日圓優惠券"],
          ticketName2: ["明石壽司「技之逸品」", "2000日圓優惠券"],
          ticketName3: ["明石壽司「技之逸品」", "2000日圓優惠券"],
          ticketName4: ["明石壽司「技之逸品」", "2000日圓優惠券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_D.jpg",
          ],
          Doer: ["JR 明石站旁"],
          time: ["平日：9:00 ～ 19:00", "星期六日 節日：9:00 ～ 18:00"],
          Http: "https://www.yokoso-akashi.jp/sushi_coupon",
          Faci: [
            "兵庫縣明石市大明石町1-1-23",
            "（明石車站Akashi諮詢處 Piole西館）",
          ],
          Calendar: ["年底年初（12/31 ～ 1/1）"],
          areaType: 3,
          price: "2,000",
        },
        {
          id: 14,
          activeId: 1,
          areaNAme: "兵庫",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
          ticketName: ["兵庫縣明石市立", "天文科學館入館券"],
          ticketName2: ["兵庫縣明石市立天文科學館", "入館券"],
          ticketName3: ["兵庫縣明石市立天文科學館", "入館券"],
          ticketName4: ["兵庫縣明石市立天文科學館", "入館券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_D.jpg",
          ],
          Doer: ["JR 明石站徒歩約15分鐘", "山陽電車人丸前站徒歩約3分鐘"],
          time: [
            "明石介紹所",
            "平日：9:00 ～ 19:00",
            "假日：9:00 ～ 18:00",
            "明石市立天文科學館",
            " 9:30 ～ 17:00（最後入館 16:30）",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "兌換地點：兵庫縣明石市",
            "大明石町1-1-23piole明石西館",
            "設施地址：兵庫縣県明石市人丸町2-6",
          ],
          Calendar: ["年底年初（可能臨時休館）"],
          areaType: 3,
          price: "700",
        },
        {
          id: 15,
          areaNAme: "兵庫",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          ticketName2: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          ticketName3: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          ticketName4: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 明石站徒步約8分鐘"],
          time: [
            "（平日）",
            "明石乘船時間：9:00 ～ 17:00",
            "岩屋乘船時間：9:00 ～ 17:00",
            "（星期六日 節日）",
            "明石乘船時間：9:00 ～ 17:00",
            "岩屋乘船時間： 9:00 ～ 17:00",
            "※詳細時刻表請參照官方網站",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "明石乘船處：兵庫縣明石市本町2-10-1",
            "岩屋乗船場：兵庫縣淡路市岩屋925-27",
            "※可在任何一側搭乘JENOVA船",
          ],
          Calendar: ["天候不佳時將會停駛"],
          areaType: 3,
          price: "530",
        },
        {
          id: 16,
          activeId: 1,
          areaNAme: "滋賀",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
          ticketName: ["滋賀長濱浪漫景點", "實體護照", "（市內觀光設施通票）"],
          ticketName2: ["滋賀長濱浪漫景點實體護照", "（市內觀光設施通票）"],
          ticketName3: ["滋賀長濱浪漫景點實體護照", "（市內觀光設施通票）"],
          ticketName4: ["滋賀長濱浪漫景點實體護照", "（市內觀光設施通票）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_D.jpg",
          ],
          Doer: ["JR 長濱站內"],
          time: ["長濱站觀光服務處：9:15 ～ 16:45"],
          Http: "https://kitabiwako.jp/spot/spot_8966",
          Faci: ["滋賀縣長濱市北船町1−5", "（長濱站觀光諮詢處）"],
          Calendar: ["年底年初"],
          areaType: 3,
          price: "1,200",
        },
        {
          id: 17,
          activeId: 1,
          areaNAme: "滋賀",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
          ticketName: ["琵琶湖大津王子大飯店37樓", "湖景餐廳Biona", "午餐套餐"],
          ticketName2: [
            "琵琶湖大津王子大飯店",
            "37樓湖景餐廳Biona",
            "午餐套餐",
          ],
          ticketName3: [
            "琵琶湖大津王子大飯店",
            "37樓湖景餐廳Biona",
            "午餐套餐",
          ],
          ticketName4: [
            "琵琶湖大津王子大飯店",
            "37樓湖景餐廳Biona",
            "午餐套餐",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_E.jpg",
          ],
          Doer: ["JR 大津站可搭乘免費接駁車約10分鐘"],
          time: [
            "營業時間：11:30 ～ 15:00",
            "用餐時間：11:30 ～ 13:00",
            "13:30 ～ 15:00",
            "（限時90分鐘，2個時間段）",
          ],
          Http: "https://www.princehotels.cn/otsu/zh-hans/restaurants/lake-view-dining-biona/",
          Faci: [
            "滋賀縣大津市Niono濱4-7-7",
            "琵琶湖大津王子大飯店",
            "（37樓湖景餐廳Biona）",
          ],
          Calendar: ["無"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 18,
          activeId: 1,
          areaNAme: "滋賀",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
          ticketName: ["琵琶湖竹生島觀光遊覽船", "（彥根港～竹生島往返）"],
          ticketName2: ["琵琶湖竹生島觀光遊覽船", "（彥根港～竹生島往返）"],
          ticketName3: ["琵琶湖竹生島觀光遊覽船", "（彥根港～竹生島往返）"],
          ticketName4: ["琵琶湖竹生島觀光遊覽船", "（彥根港～竹生島往返）"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_D.jpg",
          ],
          Doer: ["JR 彥根站搭乘免費接駁車約", "8分鐘於「彥根港」下車"],
          time: ["星期六 日 節日出航"],
          Http: "http://www.ohmitetudo.co.jp/marine/",
          Faci: ["滋賀縣彥根市松原町3755", "（彥根港乘船處）"],
          Calendar: ["平日，12/30 ～ 12/31"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 19,
          areaNAme: "滋賀",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
          ticketName: ["八幡山纜車往返劵"],
          ticketName2: ["八幡山纜車往返劵"],
          ticketName3: ["八幡山纜車往返劵"],
          ticketName4: ["八幡山纜車往返劵"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_D.jpg",
          ],
          Doer: [
            "JR 近江八幡站北口6號搭乘區",
            "前往長命寺巴士約7分",
            "至大衫町八幡山空中纜車處下車",
          ],
          time: ["9:00 ～ 17:00 （最后上山 16:30）"],
          Http: "http://www.ohmitetudo.co.jp/hachimanyama/open-price/index.html/",
          Faci: ["滋賀縣近江八幡市宮內町（山麓售票處）"],
          Calendar: ["無"],
          areaType: 3,
          price: "890",
        },
        {
          id: 20,
          areaNAme: "和歌山",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
          ticketName: ["和歌山串本海中公園", "水族館·展望塔入場券"],
          ticketName2: ["和歌山串本海中公園", "水族館·展望塔入場券"],
          ticketName3: ["和歌山串本海中公園水族館 ", "展望塔入場券"],
          ticketName4: ["和歌山串本海中公園水族館 ", "展望塔入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_E.jpg",
          ],
          Doer: ["JR 串本站搭乘免費接駁巴士約12分鐘"],
          time: ["9:00 ～ 16:30（服務窗口到 16:00）"],
          Http: "http://kushimoto.co.jp/chinese/",
          Faci: [
            "和歌山縣東牟婁郡串本町有田1157",
            "（和歌山串本海中公園入口處）",
          ],
          Calendar: ["無"],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 21,
          areaNAme: "和歌山",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
          copyright: "「DESIGNED BY EIJI MITOOKA + DON DESIGN ASSOCIATES」",
          ticketName: ["和歌山電鐵貴志川線", "1日乘車券"],
          ticketName2: ["和歌山電鐵貴志川線", "1日乘車券"],
          ticketName3: ["和歌山電鐵貴志川", "線1日乘車券"],
          ticketName4: ["和歌山電鐵貴志川", "線1日乘車券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_E.jpg",
          ],
          Doer: ["JR 和歌山站9號乘車區"],
          time: ["依車輛種類不同而各有差異"],
          Http: "http://www2.wakayama-dentetsu.co.jp/pdf/wakayama_chinese.pdf",
          Faci: ["和歌山縣和歌山市美園町5丁目2", "（和歌山電鐵和歌山站）"],
          Calendar: ["停駛日以及貓站長的上班日", "上班時間等請參照官方網站"],
          areaType: 3,
          price: "800",
        },
        // 山陽山陰
        {
          id: 22,
          areaNAme: "岡山",
          img: require("@/assets/images/allpass/erdao_A.jpg"),
          ticketName: ["兒島循環巴士乘車券及", "丹寧布手作體驗"],
          ticketName2: ["兒島循環巴士乘車券及", "丹寧布手作體驗"],
          ticketName3: ["兒島循環巴士乘車券及", "丹寧布手作體驗"],
          ticketName4: ["兒島循環巴士乘車券及", "丹寧布手作體驗"],
          tickeImg: [
            require("@/assets/images/allpass/erdao_A.jpg"),
            require("@/assets/images/allpass/erdao_B.jpg"),
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_D.jpg",
          ],
          Doer: ["JR 兒島站驗票口"],
          time: [
            "兌換時間：9:00 ～ 17:00",
            "丹寧布手作體驗：9:00 ～ 18:00",
            "（體驗時間：11:00 ～、14:00 ～）",
            "兒島循環巴士：星期五 六 日 節日",
            "FUREAI 巴士 / TOKOHAI巴士 （每天運行）",
          ],
          Http: "http://shimoden.net/rosen/kikaku/jeans.html",
          Faci: [
            "岡山縣倉敷市兒島車站前一丁目107番地",
            "（JR 兒島站巴士乘車處）",
          ],
          Calendar: [
            "丹寧布手作體驗休業日：年底年初",
            "兒島循環巴士休業日：平日一～四，年底年初",
          ],
          areaType: 4,
          price: "1,000",
        },
        {
          id: 23,
          areaNAme: "岡山",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
          ticketName: ["岡山格蘭比亞大酒店", "西點師傅主推聖代"],
          ticketName2: ["岡山格蘭比亞大酒店", "西點師傅主推聖代"],
          ticketName3: ["岡山格蘭比亞大酒店", "西點師傅主推聖代"],
          ticketName4: ["岡山格蘭比亞大酒店", "西點師傅主推聖代"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_D.jpg",
          ],
          Doer: ["JR 岡山站旁"],
          time: ["提供时间：12:00 ～ 17:00", "（酒店1樓大廳）"],
          Http: "https://granvia-oka.co.jp/cn/restaurant/#lum",
          Faci: ["岡山縣岡山市北區站元町1-5", "（1樓Lumière櫃檯）"],
          Calendar: ["無"],
          areaType: 4,
          price: "2,500",
        },
        {
          id: 24,
          areaNAme: "廣島",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
          ticketName: ["島田水產 廣島縣漁場介紹與", "海上遊覽嚴島神社"],
          ticketName2: ["島田水產 廣島縣漁場介紹", "與海上遊覽嚴島神社"],
          ticketName3: ["島田水產 廣島縣漁場介紹", "與海上遊覽嚴島神社"],
          ticketName4: ["島田水產 廣島縣漁場介紹", "與海上遊覽嚴島神社"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_D.jpg",
          ],
          Doer: ["山陽本線JR宮島口站徒歩約15分鐘", "（郵輪乘船處徒歩10分鐘）"],
          time: ["10:00 ～ 16:00"],
          Http: "https://shimadasuisan.com/kakigoya-cn-han.html",
          Faci: ["廣島縣廿日市市宮島口西1-2-6", "（kaki小屋售票處）"],
          Calendar: ["無"],
          areaType: 4,
          price: "2,200",
        },
        {
          id: 25,
          areaNAme: "廣島",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ticketName: ["瀨戶田周遊券"],
          ticketName2: ["瀨戶田周遊券"],
          ticketName3: ["瀨戶田周遊券"],
          ticketName4: ["瀨戶田周遊券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_C.jpg",
          ],
          Doer: ["JR 尾道站內"],
          time: ["尾道站觀光服務處：", "9:00 ～ 18:00"],
          Http: "https://www.ononavi.jp/fan/onotabi/guruseto/",
          Faci: ["廣島縣尾道市東禦所町1-1", "（尾道站觀光咨詢處）"],
          Calendar: ["12/29 ～ 1/3"],
          areaType: 4,
          price: "4,500",
        },
        {
          id: 26,
          areaNAme: "山口",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
          ticketName: ["山口縣防府市觀光地", "參觀通票"],
          ticketName2: ["山口縣防府市觀光地", "參觀通票"],
          ticketName3: ["山口縣防府市觀光地", "參觀通票"],
          ticketName4: ["山口縣防府市觀光地", "參觀通票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_E.jpg",
          ],
          Doer: ["JR 防府站內"],
          time: ["防府市觀光服務處：9:00 ～ 18:00"],
          Http: "https://visit-hofu.jp/ticket",
          Faci: ["山口縣防府市戎町1-1-1", "（JR 防府站 防府市觀光案內所）"],
          Calendar: ["无"],
          areaType: 4,
          price: "1,200",
        },
        {
          id: 27,
          areaNAme: "島根",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
          ticketName: ["日本庭園由志園入場券"],
          ticketName2: ["日本庭園由志園入場券"],
          ticketName3: ["日本庭園由志園入場券"],
          ticketName4: ["日本庭園由志園入場券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_E.jpg",
          ],
          Doer: ["JR 松江站6號乘車區", "搭乘巴士約50分鐘"],
          time: ["10:00 ～ 17:00（最後入園16：30）"],
          Http: "https://www.yuushien.com/",
          Faci: ["島根縣松江市八束町", "波入1260-2 （售票處）"],
          Calendar: ["12/30 ～ 12/31"],
          areaType: 4,
          price: "1,000",
        },
        // 石川
        {
          id: 28,
          areaNAme: "石川",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
          ticketName: ["加賀傳統工藝鄉", "湯之國之森入村券"],
          ticketName2: ["加賀傳統工藝鄉", "湯之國之森入村券"],
          ticketName3: ["加賀傳統工藝鄉", "湯之國之森入村券"],
          ticketName4: ["加賀傳統工藝鄉", "湯之國之森入村券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_D.jpg",
          ],
          Doer: ["JR 加賀溫泉站搭乘 CANBUS 環山線", "約40分鐘"],
          time: ["9:00 ～ 16:30（最後入圓 15:30）"],
          Http: "https://www.yunokuni.jp/mori/?lang=tw",
          Faci: ["石川縣小松市栗津溫泉Na3-3 （售票處）"],
          Calendar: ["請參照官方網站"],
          areaType: 5,
          price: "550",
        },
        {
          id: 29,
          areaNAme: "石川",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
          ticketName: ["加賀周遊巴士「CANBUS」", "1日周遊券"],
          ticketName2: ["加賀周遊巴士「CANBUS」", "1日周遊券"],
          ticketName3: ["加賀周遊巴士「CANBUS」", "1日周遊券"],
          ticketName4: ["加賀周遊巴士「CANBUS」", "1日周遊券"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_D.jpg",
          ],
          Doer: ["JR 加賀溫泉站南出口「MACHIDUKURI加賀周遊券販售處」"],
          time: ["MACHIDUKURI加賀周遊券販售處：", "8:30 ～ 18:00"],
          Http: "http://www.kaga-canbus.jp/chinese.php",
          Faci: ["石川縣加賀市作見町RI 1-3", "（Machizukuri加賀周遊券販售處）"],
          Calendar: ["無"],
          areaType: 5,
          price: "1,100",
        },
      ],
      showList: [],
      md3: [
        {
          id: 1,
          name: "所有地區",
          num: 29,
          areaType: 0,
        },
        {
          id: 2,
          name: "大阪",
          areaType: 2,
        },
        {
          id: 3,
          name: "京都",
          areaType: 1,
        },
      ],
      md4: [
        {
          id: 4,
          name: "石川",
          areaType: 5,
        },
      ],
      md5: [
        {
          id: 5,
          name: "滋賀·兵庫·和歌山",
          areaType: 3,
        },
      ],
      md7: [
        {
          id: 6,
          name: "山陰山陽",
          areaType: 4,
        },
      ],
    };
  },
  created() {
    this.showArea();
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")];
    },
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  methods: {
    change(item) {
      this.active = item.id;
      if (this.active === 1) {
        this.ticketDescribe = "觀光設施共通券(29項設施)";
        this.planName = "關西廣域周遊券5天套票";
        this.planPrice = "27,700";
        this.planName2 = "關西廣島周遊券5天套票";
        this.planPrice2 = "32,000";
        this.planName3 = "JR西日本全地區鐵路周遊券";
        this.planPrice3 = "39,000";
        this.md3[0].num = 29;
        this.showList = this.scenicSpotArr;
        let array = {
          id: 15,
          areaNAme: "兵庫",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          ticketName2: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          ticketName3: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          ticketName4: ["JENOVA號淡路島", "（明石⇔岩屋）單程船票"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 明石站徒步約8分鐘"],
          time: [
            "（平日）",
            "明石乘船時間：9:00 ～ 17:00",
            "岩屋乘船時間：9:00 ～ 17:00",
            "（星期六日 節日）",
            "明石乘船時間：9:00 ～ 17:00",
            "岩屋乘船時間： 9:00 ～ 17:00",
            "※詳細時刻表請參照官方網站",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "明石乘船處：兵庫縣明石市本町2-10-1",
            "岩屋乗船場：兵庫縣淡路市屋岩925-27",
            "※可在任何一側搭乘JENOVA船",
          ],
          Calendar: ["天候不佳時將會停駛"],
          areaType: 3,
          price: "530",
          content: "",
          content2: "",
        };
        this.showList.findIndex((item) => {
          if (item.id === 14) {
            return true;
          }
        });
        this.showList.splice(14, 0, array);
      } else {
        this.ticketDescribe = "觀光設施共通券(16項設施)";
        this.planName = "關西地區鐵路周遊券3天套票方案";
        this.planName_small = "關西地區鐵路周遊券3天套票方案";
        this.planPrice = "19,300";
        this.planName2 = "關西廣域地區鐵路周遊券5天套票方案";
        this.planPrice2 = "22,700";
        this.planName2_small = "關西廣域地區鐵路周遊券5天套票方案";
        this.planName3 = "";
        this.planPrice3 = "";
        this.md3[0].num = 16;
        this.showList = this.scenicSpotArr;
        let id15 = this.showList.findIndex((item) => {
          if (item.id === 15) {
            return true;
          }
        });
        this.showList.splice(id15, 1);
      }
      this.activeAreaArr = []; //清除已选中的地区id
      this.activeArr = [1]; //切换探游券时 默认全部
    },
    openSmall(item) {
      this.ticketDetials = item;
      this.innerDrawer = true;
    },
    open(item) {
      this.ticketDetials = item;
      this.showDialog = true;
      this.innerDrawer = true;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); // 禁止页面底部内容滑动
    },
    close2() {
      this.showDialog = false;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; // 出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    showArea() {
      this.showList = this.scenicSpotArr;
    },
    activeArea(item) {
      let index = 0;
      this.showList = [];
      if (item.areaType === 0) {
        // 选择了所有
        this.activeArr = [1]; //所有
        this.activeAreaArr = [];
        this.active === 1
          ? (this.showList = this.scenicSpotArr)
          : (this.showList = this.scenicSpotArr); //判断是否西日本
        return;
      }
      if (item.areaType !== 0) {
        // 没有选择全部地区并且选的是西日本
        let alldelIndex = this.checkArray(this.activeArr, 1); //找到复选地区中所有地区的下标
        alldelIndex !== -1 ? this.activeArr.splice(alldelIndex, 1) : ""; //如果有所有地区的下标就删除
        let activeArrDelIndex = this.checkArray(this.activeArr, item.id); //查找复选地区是否已经存在
        let activeAreaArrDelIndex = this.checkArray(
          this.activeAreaArr,
          item.areaType
        ); //查找复选地区是否已经存在
        activeArrDelIndex === -1
          ? this.activeArr.push(item.id)
          : this.activeArr.splice(activeArrDelIndex, 1); //如果目前地区存在就删除，不然就加入
        activeAreaArrDelIndex === -1
          ? this.activeAreaArr.push(item.areaType)
          : this.activeAreaArr.splice(activeAreaArrDelIndex, 1); //查找复选地区是否已经存在
        this.scenicSpotArr.forEach((item1) => {
          if (item.areaType === item1.areaType) {
            // 去重
            let newArr = [];
            for (let i = 0; i < this.activeArr.length; i++) {
              newArr.indexOf(this.activeArr[i]) == -1
                ? newArr.push(this.activeArr[i])
                : "";
            }
            this.activeArr = newArr;
          }
        });
        this.activeAreaArr = this.unique(this.activeAreaArr); //景点属性去重
        this.scenicSpotArr.forEach((item11) => {
          this.activeAreaArr.forEach((item12) => {
            item12 == item11.areaType ? this.showList.push(item11) : "";
            item12 == item11.areaType ? console.log(item11) : "";
          });
        });
      } else {
        this.activeArr = [1];
        this.showList = this.scenicSpotArr;
      }
    },
    unique(arr) {
      var result = [];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            j = ++i;
          }
        }
        result.push(arr[i]);
      }
      return result;
    },
    checkArray(arr, target) {
      return arr.indexOf(target);
    },
    handleClose(done) {
      done();
    },
    splitBatName(str) {
      return str;
    },
  },
};
</script>

<style>
@import "~@/assets/css/zh.scss";
</style>