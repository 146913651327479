<template>
  <div class="main-nav">
    <div class="main-nav2">
      <el-image :src="homeLogo" class="MB_home_icon" @click="toHome()"></el-image>
      <el-image :src="lanLogo" class="hamburger-mbimg" lazy @click="drawer = true">
      </el-image>
    </div>
    <el-drawer title="" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
      <div class="main-nav2">
        <el-image :src="homeLogo" class="MB_home_icon" @click="toHome()"></el-image>
        <el-image :src="lanLogo" class="hamburger-mbimg" lazy @click="drawer = false">
        </el-image>
      </div>
      <div class="main-smallnav" id="main-smallnav">
        <a class="app-menu-list" href="/exploreallpass/zh/">
          <div class="smallnav-select-item"><span class="main-nav-content">简体中文</span>
          </div>
        </a>
        <a class="app-menu-list" href="/exploreallpass/zh_tw/">
          <div class="smallnav-select-item smallnav-select-item-active"><span class="main-nav-content">繁體中文</span></div>
        </a>
        <a class="app-menu-list" href="/exploreallpass/en/">
          <div class="smallnav-select-item"><span class="main-nav-content">ENGLISH</span></div>
        </a>
        <a class="app-menu-list" href="/exploreallpass/ko/">
          <div class="smallnav-select-item"><span class="main-nav-content">한국어</span></div>
        </a>
      </div>
      <img class="language-bg1" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png" lazy />
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 1,
      homeLogo: require("@/assets/images/allpass/MB_home_icon.png"),
      lanLogo: require("@/assets/images/allpass/MB_line_1.png"),
      lanBg: "https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png",
      drawer: false,
      direction: "ttb",
    };
  },
  mounted() {},
  methods: {
    // 切换语言
    changeLan() {},
    toHome() {
      this.$router.push({ path: "/home" });
    },
    handleClose(done) {
      done();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-drawer__open .el-drawer.ttb {
  height: 100% !important;
}
::v-deep .el-drawer__header {
  display: none !important;
}
::v-deep .el-icon-close:before {
  display: none;
}
::v-deep .el-drawer__body {
  background-color: rgb(255, 237, 147);
}
.main-nav {
  display: block;
  width: 100% !important;
}
.main-nav2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px !important;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  .MB_home_icon {
    display: block;
    width: 25px;
    margin-left: 20px;
  }
  .PC_home_icon {
    display: none;
  }
  .main-nav2 a {
    text-decoration: none;
  }
  .hamburger-mbimg {
    padding-top: 5px !important;
    margin-right: 20px;
    width: 102px;
    height: 17px;
  }
}
.main-smallnav {
  width: 100%;
  height: auto;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffed93;
  .main-smallnav a {
    text-decoration: none;
  }
}
.smallnav-select-item-active {
  width: 127px;
  height: 39px;
  border-radius: 20px;
  background: #ff8033;
  line-height: 39px;
  text-align: center;
  margin-bottom: 49px;
  color: #fff !important;
  font-size: 22px;
  font-family: "GenSenRoundedTW-M";
}
.smallnav-select-item {
  width: 127px;
  height: 39px;
  border-radius: 20px;
  line-height: 39px;
  text-align: center;
  margin-bottom: 49px;
  color: #ff8033;
  font-size: 22px;
  font-family: "GenSenRoundedTW-M";
  padding-top: 0;
  background: #fff;
}
.smallnav-select-item-active {
  background: #ff8033;
  color: #fff !important;
}
.language-bg1 {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #ffed93;
  z-index: 2;
}
</style>